@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$phoneWidth: 360px;
$tabletWidth: 768px;
$desktopWidth: 1366px;
$LGdesktop: 1920px;

$mainFont: "Work Sans", sans-serif;

$blue: #7da3e9;
$red: #fd402d;
$peach: #ff6f61;
